import React from 'react'
import { Container } from 'react-bootstrap'

import PageWrapper from '../components/PageWrapper'
import Section from '../components/Section'
import { ReturnHomeButton } from '../components/ReturnHomeButton'
import { Title, Text } from '../components/Core'

const ThankYouPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark hideFooterLogo>
        <Section>
          <div className='pt-5'></div>
          <Container>
            <div className='text-center'>
              <div>
                <Title variant='hero' color='light'>
                  Thanks!
                </Title>
                <Text color='light'>We'll be in touch soon.</Text>
              </div>
              <div className='mt-5'>
                <ReturnHomeButton />
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default ThankYouPage
